/**
 * useConfirmAccount Composable
 *
 * Purpose:
 * This composable provides functionality to confirm accounts
 *
 * Usage Example:
 * ```javascript
 * useConfirmAccount();
 * ```
 */
export function useConfirmAccount() {
  const { $api } = useNuxtApp()
  const route = useRoute()

  // Check for confirmation token upon mounting and track confirmation
  onMounted(async () => {
    const confirmationToken = route.query.confirmation_token || route.query.token
    if (confirmationToken) {
      try {
        await $api.auth.postAuthConfirmAccount({
          confirmation_token: confirmationToken,
        })
      }
      catch (error: any) {
      // Handle any potential errors (could add logging or other error handling here)
        console.error(error)
      }
    }
  })
}
